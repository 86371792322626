.content {
  min-width: 600px;
}

.iconOk {
  color: var(--color-primary-green);
}

.iconClose {
  color: var(--color-primary-red);
}



