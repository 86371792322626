.main {
  background: linear-gradient(135deg, #fff, var(--color-primary-green-light));
  min-height: 100%;
}

.alumno {
  padding: 10px 16px 50px;
}

.content {
  padding: 16px;
}

.summary {
  display: flex;
}

.image {
  width: 250px;
  height: 200px;
  object-fit: cover;
  margin: 16px;
}

.section {
  margin: 5px 0;
}

.academy {
  display: flex;
  align-items: center;
}

.academy i {
  margin-right: 8px;
}

.academy strong {
  margin-left: 6px;
}

.chip {
  margin: 5px 8px;
  cursor: pointer;
}

.chip:first-of-type {
  margin-left: 0;
}

.ficha {
  margin-top: 12px;
}

.accordion {
  margin-top: 36px;
}
.progress {
  font-weight: bold;
}