.actions {
  display: flex;
  align-items: flex-end;
}

.placeholder {
  color: var(--color-gray);
  font-style: italic;
}

.observaciones {
  max-width: 450px;
}